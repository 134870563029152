<template>
  <el-card class="box-card">
    <div slot="header" class="clearfix">
      <h3 class="inBlock">订单列表</h3>
    </div>
    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
      <el-tab-pane label="自提订单" name="first">
        <div class="box">
          <div class="condition">
            <div class="time">
              <span class="orderTime">下单时间: </span>
              <el-date-picker
                v-model="TimeValue"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                align="left"
                size="small"
              >
              </el-date-picker>
              <span class="orderTime">退款审核时间: </span>
              <el-date-picker
                v-model="refundTime"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                align="left"
                size="small"
              >
              </el-date-picker>
              <span class="orderTime">核销时间: </span>
              <el-date-picker
                v-model="confirmTime"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                align="left"
                size="small"
              >
              </el-date-picker>
              <span class="orderTime">申请退款时间: </span>
              <el-date-picker
                v-model="applyTime"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                align="left"
                size="small"
              >
              </el-date-picker>
              <span class="orderTime">支付时间: </span>
              <el-date-picker
                v-model="orderPayTime"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                align="left"
                size="small"
              >
              </el-date-picker>
            </div>

            <div class="type">
              <p>
                <span>订单编号:</span>
                <el-input
                  placeholder="请输入订单编号"
                  size="small"
                  v-model="form.order_no"
                  clearable
                >
                </el-input>
              </p>
              <p>
                <span>门店ID:</span>
                <el-input
                  placeholder="请输入门店ID"
                  size="small"
                  v-model="form.store_id"
                  clearable
                >
                </el-input>
              </p>
              <p>
                <span>门店电话:</span>
                <el-input
                  placeholder="请输入门店电话"
                  v-model="form.store_phone"
                  size="small"
                  clearable
                >
                </el-input>
              </p>
              <p>
                <span>门店名称:</span>
                <el-input
                  placeholder="请输入门店名称"
                  size="small"
                  v-model="form.store_name"
                  clearable
                >
                </el-input>
              </p>
            </div>
            <el-button type="primary" plain size="small" @click="search"
              >点击筛选</el-button
            >
            <el-button
              @click="shuaxin"
              style="float: right"
              type="primary"
              size="small"
              >刷新表格</el-button
            >
            <span
              class="addshop shou"
              style="
                background-color: #fc5531;
                padding: 5px 10px;
                margin-left: 10px;
                color: #fff;
                border-radius: 4px;
              "
              @click="exportShop"
              >导出订单 <i class="el-icon-folder-opened"></i
            ></span>
          </div>
          <el-button-group>
            <el-button
              size="medium"
              v-for="(item, index) in typelist"
              class="statusButton"
              :key="index"
              @click="setType(item.type)"
              :style="form.type == item.type ? 'background-color: #ecf5ff; color: #409eff': ''"
              >{{ item.text }}</el-button
            >
          </el-button-group>
          <el-table size="medium" style="width: 100%" :data="list" border>
            <el-table-column type="expand">
              <div class="orderGoods" slot-scope="scope">
                <h4>订单商品</h4>
                <div
                  class="goodsDetail"
                  v-for="item in scope.row.order_detail"
                  :key="item.id"
                >
                  <div class="img">
                    <img :src="imgurl + item.cover_pic" alt="" />
                  </div>
                  <div class="data">
                    <p class="sheng">{{ item.goods_name }}</p>
                    <p class="sheng">
                      <!-- 规格:
                      <span v-if="item.attr != ''" style="margin-right: 20px">{{
                        item.attr
                      }}</span>
                      <span v-else style="margin-right: 20px">默认</span> -->
                      数量:<span>{{ item.num }}</span>
                    </p>
                    <p class="sheng">
                      小计: <span>{{ item.total_price }}</span
                      >元
                    </p>
                  </div>
                  <!-- <div class="yongjin">
                    <p>
                      佣金额: <span>{{ item.agent_share_price }}</span
                      >元
                    </p>
                  </div> -->
                </div>
              </div>
            </el-table-column>
            <el-table-column label="门店ID" prop="store_id">
            </el-table-column>
            <el-table-column label="订单编号" prop="order_no" width="200">
            </el-table-column>
            <el-table-column label="用户名" width="100">
              <div v-if="scope.row.name != null" slot-scope="scope">
                {{ scope.row.name }}
              </div>
            </el-table-column>
            <el-table-column label="联系方式" width="110">
              <div v-if="scope.row.mobile" slot-scope="scope">
                {{ scope.row.mobile }}
              </div>
            </el-table-column>
            <el-table-column label="下单时间" width="180">
              <div slot-scope="scope">{{ scope.row.addtime | datefilter }}</div>
            </el-table-column>
            <el-table-column label="门店名称" width="130">
              <div slot-scope="scope">
                <span>{{ scope.row.store_name }}</span>
              </div>
            </el-table-column>
            <el-table-column label="门店电话" width="110">
              <div slot-scope="scope">
                {{ scope.row.store_phone }}
              </div>
            </el-table-column>
            <el-table-column label="应付金额" width="100" prop="total_price">
            </el-table-column>
            <el-table-column label="实付金额" width="100" prop="pay_price">
            </el-table-column>
            <el-table-column label="订单状态" width="150">
              <div class="orderstatus" slot-scope="scope">
                <el-tag v-if="scope.row.is_pay == 0 && scope.row.is_cancel == 0" size="mini" type="danger" effect="dark">未付款</el-tag>
                <el-tag v-if="scope.row.is_cancel == 1 && scope.row.is_pay == 0" size="mini" type="info" effect="dark">已取消</el-tag>
                <el-tag v-if="scope.row.is_confirm == 0 && scope.row.is_pay == 1 && scope.row.order_refund_status != 3" size="mini"  type="success" effect="dark">待自提</el-tag>
                <el-tag v-if="scope.row.is_confirm == 1" size="mini"  type="success">已自提</el-tag>
                <el-tag v-if="scope.row.order_refund_status == 3" size="mini"  type="warning">已退款</el-tag>
              </div>
            </el-table-column>
            <el-table-column label="支付时间" width="170">
              <div slot-scope="scope">
                <span v-if="scope.row.pay_time != 0">{{
                  scope.row.pay_time | datefilter
                }}</span>
              </div>
            </el-table-column>
            <el-table-column label="退款时间" width="170">
              <div slot-scope="scope">
                <span v-if="scope.row.refund_time">{{
                  scope.row.refund_time | datefilter
                }}</span>
                <span v-else>暂未退款</span>
              </div>
            </el-table-column>
            <el-table-column label="核销时间" width="170">
              <div slot-scope="scope">
                <span v-if="scope.row.confirm_time != 0">{{
                  scope.row.confirm_time | datefilter
                }}</span>
                <span v-else>暂未核销</span>
              </div>
            </el-table-column>
            <!-- <el-table-column
              label="佣金额"
              width="80"
              prop="agent_share_price"
            >
            </el-table-column> -->
            <el-table-column label="操作">
              <div class="caozuo" slot-scope="scope">
                <el-button
                  size="mini"
                  type="primary"
                  round
                  @click="GoDetail(scope.row.id)"
                  >详情</el-button
                >
              </div>
            </el-table-column>
          </el-table>
          <el-pagination
            @current-change="CurrentChange"
            layout="total, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </el-tab-pane>
      <el-tab-pane label="物流订单" name="second">
        <Logistics></Logistics>
      </el-tab-pane>
    </el-tabs>
    <el-dialog title="导出范围" :visible.sync="exportShow" width="25%">
      <div>
        <p style="padding: 0 0 20px 0; font-size: 12px; color: #e6a23c">
          将导出满足筛选条件的门店!
        </p>
        <el-radio v-model="exportType" :label="1">当前页</el-radio>
        <el-radio v-model="exportType" :label="2">导出所有</el-radio>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="exportShows">取 消</el-button>
        <el-button type="primary" @click="exportShop2">确 定</el-button>
      </span>
    </el-dialog>
  </el-card>
</template>

<script>
import { getorderlist } from "@/api/api.js";
import Logistics from "@/components/Logistics_orderlist.vue";
import { getSystemsettings } from "../../api/getSystemsetting.js";
import { filenameFormat } from "@/utils/index";
export default {
  components: {
    Logistics,
  },
  name: "orderList",
  data() {
    return {
      getorderlist: getorderlist,
      orderItem: "",
      orderType: [
        { id: 1, name: "订单号" },
        { id: 2, name: "用户名" },
        { id: 3, name: "用户ID" },
        { id: 4, name: "商品名称" },
        { id: 5, name: "收件人" },
        { id: 6, name: "收件人电话" },
      ],
      typelist: [
        { type: 0, text: "全部" },
        { type: 1, text: "待支付" },
        { type: 2, text: "待自提" },
        { type: 3, text: "已自提" },
        { type: 4, text: "已退款" },
        { type: 6, text: "已取消" },
      ],
      exportShow: false,
      activeName: "first",
      form: {
        order_no: "",
        start_time: "",
        end_time: "",
        page: 1,
        store_name: "",
        type: 0,
        export: "",
        store_id: "", // 门店ID
        store_phone: "", // 门店电话
        refund_start_time: "", // 退款审核开始时间
        refund_end_time: "", // 退款审核结束时间
        confirm_start_time: "", // 订单核销开始时间
        confirm_end_time: "", // 订单核销结束
        refundadd_start_time: "", //订单申请开始时间
        refundadd_end_time: "", //订单核销结束
        pay_start_time:"",   // 订单支付开始时间
        pay_end_time:""     // 订单支付结束时间
      },
      exportType: 1,
      total: 0,
      TimeValue: null,
      refundTime: null,    // 退款审核
      confirmTime: null,   // 订单核销时间
      applyTime: null,     // 订单申请时间
      orderPayTime:null,   // 下单支付时间
      list: [],
    };
  },
  watch: {
    TimeValue: function(newVal, oldVal) {
      if (newVal != null) {
        this.form.start_time = this.$timeTo.time4(this.TimeValue[0]);
        this.form.end_time = this.$timeTo.time4(this.TimeValue[1]);
      } else {
        this.form.start_time = "";
        this.form.end_time = "";
      }
    },
    refundTime: function(newVal, oldVal) {
      if (newVal != null) {
        this.form.refund_start_time = this.$timeTo.time4(this.refundTime[0]);
        this.form.refund_end_time = this.$timeTo.time4(this.refundTime[1]);
      } else {
        this.form.refund_start_time = "";
        this.form.refund_end_time = "";
      }
    },
    confirmTime: function(newVal, oldVal) {
      if (newVal != null) {
        this.form.confirm_start_time = this.$timeTo.time4(this.confirmTime[0]);
        this.form.confirm_end_time = this.$timeTo.time4(this.confirmTime[1]);
      } else {
        this.form.confirm_start_time = "";
        this.form.confirm_end_time = "";
      }
    },
    applyTime: function(newVal, oldVal) {
      if (newVal != null) {
        this.form.refundadd_start_time = this.$timeTo.time4(this.applyTime[0]);
        this.form.refundadd_end_time = this.$timeTo.time4(this.applyTime[1]);
      } else {
        this.form.refundadd_start_time = "";
        this.form.refundadd_end_time = "";
      }
    },
    orderPayTime: function(newVal, oldVal) {
      if (newVal != null) {
        this.form.pay_start_time = this.$timeTo.time4(this.orderPayTime[0]);
        this.form.pay_end_time = this.$timeTo.time4(this.orderPayTime[1]);
      } else {
        this.form.pay_start_time = "";
        this.form.pay_end_time = "";
      }
    },
  },
  methods: {
    // 切换type
    setType(type) {
      this.form.type = type;
      this.form.export = '';
      this.page = 1;
      this.getlist();
    },
    // 导出订单
    exportShop() {
      this.exportShow = true;
    },
    // 确认导出
    exportShop2() {
      this.exportShow = false;
      this.form.export = "export";
      this.form.export_type = this.exportType;
      console.log(this.form);
      this.getlist();
    },

    // 取消导出
    exportShows() {
      this.exportShow = false;
    },

    shuaxin() {
      this.getlist();
    },

    // tab切换
    handleClick(tab, event) {
      console.log(tab.name);
      localStorage.setItem("name", tab.name);
      // this.getlist();
    },
    SizeChange(size) {
      this.form.per_page = size;
      this.getlist();
    },

    CurrentChange(page) {
      this.form.page = page;
      this.getlist();
    },

    async getlist() {
      if (this.form.export == "export") {
        console.log(this.form);
        let { data, types, request } = await getorderlist(this.form, "blob");
        let blob = new Blob([data], { type: "application/vnd.ms-excel" }); //type是文件类，详情可以参阅blob文件类型
        // decodeURI(request.getResponseHeader("content-disposition").split('=')[1]);
        // let filename = decodeURI(
        //   request.getResponseHeader("content-disposition")
        // );
        console.log('blob=======',blob);
        let objectUrl = URL.createObjectURL(blob); //生成下载链接
        let a = document.createElement("a"); //创建a标签用于文件下载
        a.href = objectUrl; //赋值下载路径
        a.download = filenameFormat(
          request.getResponseHeader("content-disposition")
        ); //下载的文件名称（非必填）
        // console.log('filename====',filename)
        document.body.appendChild(a); //插入DOM树
        a.click(); //点击a标签触发
        document.body.removeChild(a); //删除a标签
        this.form.export = '';
      } else {
        console.log("1");
        this.form.order_no = this.form.order_no.trim();
        this.form.offline = 1;
        const { data } = await getorderlist(this.form, "");
        if (data.code != 200) return this.$message.error(data.data);
        this.list = data.data.order_list.data;
        console.log(this.list);
        this.total = data.data.order_list.total;
      }
    },

    search() {
      this.form.page = 1;
      this.form.export = '';
      this.getlist();
    },
    setOrderType(e) {
      this.orderItem = e;
    },
    GoDetail(id) {
      this.$router.push({
        path: "/order/detail",
        query: { id },
      });
    },
  },
  mounted() {
    // alert(localStorage.getItem('name'))
    this.orderItem = this.orderType[0].name;
    this.getlist();
    if (localStorage.getItem("name")) {
      this.activeName = localStorage.getItem("name");
    }
    getSystemsettings.then((res) => {
      this.imgurl = res;
    });
  },
};
</script>

<style scoped lang="less">
.box {
  /deep/.orderGoods {
    border: 1px solid #f1f1f1;
    padding: 10px;
    h4 {
      margin: 0px 0 10px 15px;
      font-weight: normal;
      font-size: 18px;
    }
    .goodsDetail {
      background-color: #f1f3f4;
      margin-right: 50px;
      padding: 10px;
      margin-bottom: 5px;
      display: flex;
      .img {
        width: 77px;
        height: 77px;
        background-color: #e9ebec;
        margin-right: 10px;
        img {
          width: 100%;
        }
      }
      .data {
        p {
          &:nth-child(2) {
            margin: 15px 0;
          }
          span {
            color: #db5376;
          }
        }
      }
      .yongjin {
        display: flex;
        align-items: center;
        margin-left: 200px;
        span {
          color: red;
          margin-left: 10px;
        }
      }
    }
  }
  .orderstatus {
    .el-tag {
      margin: 0 10px 2px 0;
    }
  }
  .condition {
    height: 170px;
    background: #f1f3f4;
    padding: 10px;
    margin: 20px 0;
    .orderTime {
      &:nth-child(n + 2) {
        margin-left: 20px;
      }
      display: inline-block;
      font-size: 14px;
    }
    .type {
      display: flex;
      padding: 10px 0;
      .el-input {
        width: 260px;
      }
      p {
        margin-right: 10px;
        font-size: 14px;
        span {
          display: inline-block;
        }
      }
    }
  }
}
.statusButton{
  border-bottom: none !important;
  border-radius: 0 !important;
}
.check {
  display: inline-block;
  margin-left: 30px;
  font-size: 14px;
  span {
    margin-right: 10px;
    display: inline-block;
  }
}

/deep/.warning-row {
  background: oldlace;
}

/deep/.success-row {
  background: #f0f9eb;
}
.el-table /deep/td {
  padding: 5px 0;
}
</style>
